import React, { Component } from 'react';
import { Col, Row, Dropdown, Table, Form } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import ActionButton from 'components/common/ActionButton';
/* import team2 from 'assets/img/team/2.jpg';
import team3 from 'assets/img/team/3.jpg';
import team4 from 'assets/img/team/4.jpg';
import team13 from 'assets/img/team/13.jpg'; */
import Avatar from 'components/common/Avatar';
import Parse from 'parse';
Parse.serverURL = 'https://parseapi.back4app.com'; // This is your Server URL
// Remember to inform BOTH the Back4App Application ID AND the JavaScript KEY
Parse.initialize(
  'iYGJTat7H1sGd94LhYK8XFQw59TJpTo1MluQ8Dhi', // This is your Application ID
  'cfiF9113gvFLstOcbfxk1kuHKl9220q7U3jHpiqY' // This is your Javascript key
);
class ListeCompagnies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      companies: []
    };
  }

  componentDidMount() {
    var Company = Parse.Object.extend('Company');
    var companyQuery = new Parse.Query(Company);
    companyQuery.include('country');
    companyQuery.find().then(
      result => {
        var companies = result.map(res => {
          return {
            id: res.id,
            name: res.get('name'),
            email: res.get('email'),
            phone: res.get('phone'),
            type: res.get('companyType'),
            image: res.get('image')._url,
            status: { title: 'Processing', type: 'primary', icon: 'redo' },
            country: res.get('country').get('name')
          };
        });
        this.setState({ companies: companies });
      },
      error => {
        console.log(error);
      }
    );

    var Country = Parse.Object.extend('Country');
    var query = new Parse.Query(Country);
    query.find().then(
      result => {
        var serverCountries = result.map(res => {
          return {
            id: res.id,
            name: res.get('name')
          };
        });
        this.setState({ countries: serverCountries });
      },
      error => {
        console.log(error);
      }
    );
  }

  handleCountryChange = e => {
    var Country = Parse.Object.extend('Country');
    var current = new Country();
    current.id = e.target.value;
    var Company = Parse.Object.extend('Company');
    var query = new Parse.Query(Company);
    query.equalTo('companyId', current);
    query.find().then(
      result => {
        var companies = result.map(res => {
          return {
            id: res.id,
            name: res.get('name')
          };
        });
        this.setState({ companies: companies });
      },
      error => {
        console.log(error);
      }
    );
  };

  render() {
    const TableRow = ({ data }) => (
      <tr className="align-middle">
        <td className="text-nowrap">
          <div className="d-flex align-items-center">
            <Avatar src={data.image} size="l" name={data.name} />
            <div className="ms-2">{data.name}</div>
          </div>
        </td>
        <td className="text-nowrap">{data.email}</td>
        <td className="text-nowrap">{data.phone}</td>
        <td className="text-nowrap">{data.type}</td>
        <td>
          <SoftBadge pill bg={data.status.type}>
            {data.status.title}
            <FontAwesomeIcon icon={data.status.icon} className="ms-2" />
          </SoftBadge>
        </td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
          />
          <ActionButton
            icon="eye"
            title="Agencies"
            variant="action"
            className="p-0"
          />
        </td>
      </tr>
    );

    /*  const customers = [
      {
        name: 'Ricky Antony',
        avatar: team4,
        email: 'ricky@example.com',
        phone: '(201) 200-1851',
        address: '2392 Main Avenue, Penasauka',
        amount: '$99',
        status: { title: 'Completed', type: 'success', icon: 'check' }
      },
      {
        name: 'Emma Watson',
        avatar: team13,
        email: 'emma@example.com',
        phone: '(212) 228-8403',
        address: '2289 5th Avenue, New York',
        status: { title: 'Completed', type: 'success', icon: 'check' }
      },
      {
        name: 'Rowen Atkinson',
        avatar: null,
        email: 'rown@example.com',
        phone: '(201) 200-1851',
        address: '112 Bostwick Avenue, Jersey City',
        amount: '$755',
        status: { title: 'Processing', type: 'primary', icon: 'redo' }
      },
      {
        name: 'Antony Hopkins',
        avatar: team2,
        email: 'antony@example.com',
        phone: '(901) 324-3127',
        address: '3448 Ile De France St #242',
        amount: '$50',
        status: { title: 'On Hold', type: 'secondary', icon: 'ban' }
      },
      {
        name: 'Jennifer Schramm',
        avatar: team3,
        email: 'jennifer@example.com',
        phone: '(828) 382-9631',
        address: '659 Hannah Street, Charlotte',
        amount: '$150',
        status: { title: 'Pending', type: 'warning', icon: 'stream' }
      }
    ]; */

    return (
      <div>
        <Row className="gx-0 kanban-header rounded-2 px-card py-2 mt-2 mb-3">
          <Col className="d-flex align-items-center">
            <h5 className="mb-0">Compagnies</h5>
            <IconButton
              variant="falcon-default"
              size="sm"
              className="ms-3"
              icon={['far', 'star']}
            />
            <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
            <Form.Group as={Col} md="3" controlId="countryId">
              <Form.Select
                list="agency-list"
                id="agencyId"
                name="agencyId"
                onChange={this.handleCountryChange}
              >
                <option key="11" value="choix">
                  Filtrer par pays
                </option>
                {this.state.countries.map(country => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="auto" as={Flex} alignItems="center">
            <IconButton
              variant="falcon-primary"
              size="sm"
              icon="plus"
              iconClassName="me-2"
              className="me-2 d-none d-md-block"
            >
              Ajouter
            </IconButton>
            <Dropdown align="end" className="font-sans-serif">
              <Dropdown.Toggle
                size="sm"
                variant="falcon-default"
                className="dropdown-caret-none"
              >
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-2">
                <Dropdown.Item href="#!">Copy link</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#!">Settings</Dropdown.Item>
                <Dropdown.Item href="#!">Themes</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#!" className="text-danger">
                  Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <FalconComponentCard>
          <FalconComponentCard.Header
            title="Compagnies et Agences"
            className="border-bottom"
          >
            <p className="mt-2 mb-0">
              Veuillez cliquer sur le bouton{' '}
              <b>
                <code>Détail</code>
              </b>
              , pour voir la liste des agences de chaque Compagnie. Mais tout en
              haut,vous pouvez <code>filtrer</code> ce tableau de compagnies{' '}
              <code>en fonction de chaque pays.</code>
            </p>
          </FalconComponentCard.Header>
          <FalconComponentCard.Body>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Company Type</th>
                  <th scope="col">Status</th>
                  <th className="text-end" scope="col">
                    Detail
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.companies.map(customer => (
                  <TableRow data={customer} key={customer.id} />
                ))}
              </tbody>
            </Table>
          </FalconComponentCard.Body>
        </FalconComponentCard>
      </div>
    );
  }
}

export default ListeCompagnies;
