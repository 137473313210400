import React, { Component } from 'react';
import { Col, Row, Table, Dropdown, Modal, Form } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import ActionButton from 'components/common/ActionButton';
import IconButton from 'components/common/IconButton';
import { companies } from 'data/kanban';
import GroupMember from '../app/kanban/GroupMember';
import InviteToBoard from '../app/kanban/InviteToBoard';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Image from 'react-bootstrap/Image';
import Parse from 'parse';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

Parse.serverURL = 'https://parseapi.back4app.com'; // This is your Server URL
Parse.initialize(
  'iYGJTat7H1sGd94LhYK8XFQw59TJpTo1MluQ8Dhi', // This is your Application ID
  'cfiF9113gvFLstOcbfxk1kuHKl9220q7U3jHpiqY' // This is your Javascript key
);
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      companiesTable: [],
      agencies: [],
      selectedFile: null,
      iconImageFile: null,
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
      lgShow: false
    };
    this.addService = this.addService.bind(this);
  }

  componentDidMount() {
    var AgencyService = Parse.Object.extend('AgencyService');
    var query = new Parse.Query(AgencyService);
    query.include('agencyId');
    query.include('serviceId');
    query.find().then(
      result => {
        var services = result.map(res => {
          return {
            id: res.id,
            agencyName: res.get('agencyId').get('name'),
            serviceName: res.get('serviceId').get('name'),
            serviceDescription: res.get('serviceId').get('description'),
            maximumTime: res.get('serviceId').get('maximumTime'),
            minimumTime: res.get('serviceId').get('minimumTime')
          };
        });
        this.setState({ services: services });
      },
      error => {
        alert(error);
      }
    );

    var Company = Parse.Object.extend('Company');
    var companyQuery = new Parse.Query(Company);
    companyQuery.include('country');
    companyQuery.find().then(
      result => {
        var companies = result.map(res => {
          return {
            id: res.id,
            name: res.get('name'),
            country: res.get('country').get('name')
          };
        });
        this.setState({ companiesTable: companies });
      },
      error => {
        console.log(error);
      }
    );
  }

  handleCompanieChange = e => {
    var Company = Parse.Object.extend('Company');
    var current = new Company();
    current.id = e.target.value;
    var Agency = Parse.Object.extend('Agency');
    var query = new Parse.Query(Agency);
    query.equalTo('companyId', current);
    query.find().then(
      result => {
        var agencies = result.map(res => {
          return {
            id: res.id,
            name: res.get('name')
          };
        });
        this.setState({ agencies: agencies });
      },
      error => {
        console.log(error);
      }
    );
  };

  handleServiceChange = e => {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  onFileChange = event => {
    var fileUploadControl = document.getElementById('image_file');
    var file = fileUploadControl.files[0];
    var name = event.target.files[0].name;
    var iconImageFile = new Parse.File(name, file);
    iconImageFile.save().then(() => {
      return this.setState({
        selectedFile: event.target.files[0],
        image: iconImageFile._url,
        iconImageFile: iconImageFile
      });
    });
  };

  addService() {
    console.log('test');
    if (this.state.iconImageFile !== null) {
      var AgencyService = Parse.Object.extend('AgencyService');
      var agencyService = new AgencyService();
      var Service = Parse.Object.extend('Service');
      var service = new Service();
      service.set('name', this.state.name);
      service.set('description', this.state.description);
      service.set('minimumTime', this.state.minimumTime.parseInt());
      service.set('maximumTime', this.state.maximumTime.parseInt());
      service.set('image', this.state.iconImageFile);
      service.set('serviceTypeId', {
        __type: 'Pointer',
        className: 'ServiceType',
        objectId: 'bpfTig7I7N'
      });
      service
        .save()
        .then(result => {
          console.log(result);
          agencyService.set('agencyId', {
            __type: 'Pointer',
            className: 'Agency',
            objectId: this.state.agencyId
          });
          agencyService.set('serviceId', {
            __type: 'Pointer',
            className: 'Service',
            objectId: result.id
          });
          agencyService.save().then(() => {
            Swal.fire({
              title: 'Féliciation',
              text: `Service ajouté avec succès`,
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              window.location.reload();
              toast.success(`Service ajouté avec succès`);
            });
          });
        })
        .catch(data => {
          alert('Erreur', data);
        });
    } else {
      Swal.fire({
        title: 'Erreur',
        text: `Veuillez choisir une image avant de valider`,
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        window.location.reload();
      });
    }
  }

  setLgShow = lgShow => {
    this.setState({ lgShow: lgShow });
  };

  render() {
    return (
      <div>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={() => this.setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Ajout d'un service
            </Modal.Title>
            <FalconCloseButton onClick={() => this.setLgShow(false)} />
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="companyId">
                  <Form.Label htmlFor="companyId">Compagnie</Form.Label>
                  <Form.Select
                    list="company-list"
                    name="company"
                    onChange={this.handleCompanieChange}
                  >
                    <option key="1" value="choix">
                      Choisir la compagnie
                    </option>
                    {this.state.companiesTable.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="countryId">
                  <Form.Label htmlFor="agencyId">Agence</Form.Label>
                  <Form.Select
                    list="agency-list"
                    id="agencyId"
                    name="agencyId"
                    onChange={this.handleServiceChange}
                  >
                    <option key="11" value="choix">
                      Choisir l'agence
                    </option>
                    {this.state.agencies.map(agency => (
                      <option key={agency.id} value={agency.id}>
                        {agency.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="exampleLastName">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Service ..."
                    defaultValue="Service ..."
                    name="name"
                    onChange={this.handleServiceChange}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="exampleDescription">
                  <Form.Label>Description</Form.Label>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id="basic-form-textarea"
                      rows="3"
                      name="description"
                      placeholder="Description"
                      onChange={this.handleServiceChange}
                    ></textarea>
                  </div>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="minimumTime">
                  <Form.Label>Temps minimal(Minutes)</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="5"
                    defaultValue="5"
                    name="minimumTime"
                    onChange={this.handleServiceChange}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="maximumTime">
                  <Form.Label>Temps maximal(Minutes)</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="10"
                    defaultValue="10"
                    name="maximumTime"
                    onChange={this.handleServiceChange}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group as={Col} md="6" controlId="fichier">
                  <div className="mb-3">
                    <label className="form-label">Choisir l'icone</label>
                    <input
                      className="form-control"
                      type="file"
                      id="image_file"
                      name="image_file"
                      onChange={this.onFileChange}
                    />
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="6" className="ml-4">
                  <Image
                    src={this.state.image}
                    roundedCircle
                    style={{ width: 100, height: 100 }}
                  />
                </Form.Group>
              </Row>
              <Row
                as={Col}
                md="4"
                className="mt-4"
                style={{ justifyContent: 'center' }}
              >
                <button
                  className="btn btn-primary"
                  onClick={e => {
                    e.preventDefault();
                    this.addService();
                  }}
                >
                  Valider
                </button>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        <Row className="gx-0 kanban-header rounded-2 px-card py-2 mt-2 mb-3">
          <Col className="d-flex align-items-center">
            <h5 className="mb-0">Services</h5>
            <IconButton
              variant="falcon-default"
              size="sm"
              className="ms-3"
              icon={['far', 'star']}
            />
            <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
            <GroupMember
              avatarSize="l"
              users={companies}
              showMember={4}
              className="d-none d-md-block"
            />
            <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
            <InviteToBoard />
          </Col>
          <Col xs="auto" as={Flex} alignItems="center">
            <IconButton
              variant="falcon-success"
              size="sm"
              icon="plus"
              iconClassName="me-2"
              className="me-2 d-none d-md-block"
              onClick={() => this.setLgShow(true)}
            >
              Ajouter
            </IconButton>
            <IconButton
              variant="falcon-primary"
              size="sm"
              icon="plus"
              iconClassName="me-2"
              className="me-2 d-none d-md-block"
            >
              Affecter
            </IconButton>
            <Dropdown align="end" className="font-sans-serif">
              <Dropdown.Toggle
                size="sm"
                variant="falcon-default"
                className="dropdown-caret-none"
              >
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-2">
                <Dropdown.Item href="#!">Copy link</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#!">Settings</Dropdown.Item>
                <Dropdown.Item href="#!">Themes</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#!" className="text-danger">
                  Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="mb-3 g-3">
          <Col lg={12}>
            <FalconComponentCard noGuttersBottom>
              <FalconComponentCard.Header
                title="Liste de tous les services"
                className="border-bottom"
              >
                <p className="mt-2 mb-0">
                  Voir la <code>Liste</code> de tous les services.
                </p>
              </FalconComponentCard.Header>
              <FalconComponentCard.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th scope="col">Service</th>
                      <th scope="col">Description</th>
                      <th scope="col">Agence</th>
                      <th scope="col">Délai(min)</th>
                      <th className="text-end" scope="col">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.services.length !== 0 &&
                      this.state.services.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.serviceName}</td>
                            <td>{data.serviceDescription}</td>
                            <td>{data.agencyName}</td>
                            <td>{data.minimumTime}</td>
                            <td className="text-end">
                              <ActionButton
                                icon="edit"
                                title="Edit"
                                variant="action"
                                className="p-0 me-2"
                              />
                              <ActionButton
                                icon="trash-alt"
                                title="Delete"
                                variant="action"
                                className="p-0"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Services;
