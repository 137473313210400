import React, { Component } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import Parse from 'parse';
import { toast } from 'react-toastify';
import * as ReactBootstrap from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Swal from 'sweetalert2';
import Image from 'react-bootstrap/Image';
Parse.serverURL = 'https://parseapi.back4app.com'; // This is your Server URL
// Remember to inform BOTH the Back4App Application ID AND the JavaScript KEY
Parse.initialize(
  'iYGJTat7H1sGd94LhYK8XFQw59TJpTo1MluQ8Dhi', // This is your Application ID
  'cfiF9113gvFLstOcbfxk1kuHKl9220q7U3jHpiqY' // This is your Javascript key
);
class FormValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      serverCountries: [],
      selectedFile: null,
      iconImageFile: null,
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,
      message: '',
      imageInfos: [],
      address: '',
      nom: '',
      digitalId: '',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    var Country = Parse.Object.extend('Country');
    var query = new Parse.Query(Country);
    query.find().then(
      result => {
        var serverCountries = result.map(res => {
          return {
            id: res.id,
            name: res.get('name')
          };
        });
        this.setState({ serverCountries: serverCountries });
      },
      error => {
        console.log(error);
      }
    );

    var Company = Parse.Object.extend('Company');
    var companyQuery = new Parse.Query(Company);
    companyQuery.include('country');
    companyQuery.find().then(
      result => {
        var companies = result.map(res => {
          return {
            id: res.id,
            name: res.get('name'),
            country: res.get('country').get('name')
          };
        });
        this.setState({ companies: companies });
      },
      error => {
        console.log(error);
      }
    );
  }

  handleChange(e) {
    const name = e.target.name;
    console.log('name', name);
    this.setState({ [name]: e.target.value });
  }

  handleCompanyChange(e) {
    const name = e.target.name;
    console.log('name', this.state.nom);
    this.setState({ [name]: e.target.value });
    if (name == 'country') {
      console.log('companies', this.state.companies);
      let tab = this.state.companies.filter(element => {
        return element.country === e.target.value;
      });
      this.setState({ companies: tab });
    }
  }

  handleSubmit() {
    if (this.state.iconImageFile !== null) {
      var Company = Parse.Object.extend('Company');
      var company = new Company();
      company.set('name', this.state.nom);
      company.set('companyType', this.state.type);
      company.set('email', this.state.email);
      company.set('phone', this.state.phone);
      company.set('image', this.state.iconImageFile);
      company.set('country', {
        __type: 'Pointer',
        className: 'Country',
        objectId: 'BrAgiZO0M4'
      });
      company
        .save()
        .then(() => {
          Swal.fire({
            title: 'Féliciation',
            text: `Ajout d'une compagnie éffectuée avec succès`,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
            toast.success(`Ajout d'une compagnie éffectuée avec succès`);
          });
        })
        .catch(data => {
          alert('Erreur de récupération des campagnes', data);
        });
    } else {
      Swal.fire({
        title: 'Erreur',
        text: `Veuillez choisir une image avant de valider`,
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        window.location.reload();
      });
    }
  }

  handleSubmitAgence() {
    var fileUploadControl = document.getElementById('image_file');
    var file = fileUploadControl.files[0];
    var name = this.state.selectedFile.name;
    var iconImageFile = new Parse.File(name, file);
    iconImageFile.save().then(() => {
      console.log(iconImageFile._url);
      var Agency = Parse.Object.extend('Agency');
      var agency = new Agency();
      agency.set('image', iconImageFile);
      agency.set('name', this.state.nom);
      agency.set('digitalId', this.state.digitalId);
      agency.set('address', this.state.address);
      // agency.set('agencyGeoPoint', '');
      agency.set('companyId', {
        __type: 'Pointer',
        className: 'Company',
        objectId: this.state.companyId
      });
      agency
        .save()
        .then(result => {
          console.log(result);
          Swal.fire({
            title: 'Féliciation',
            text: `Ajout d'une agence éffectuée avec succès`,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
            toast.success(`Ajout d'une agence éffectuée avec succès`);
          });
        })
        .catch(data => {
          console.log(data);
          alert(`Erreur d'ajout de l'agence `, data);
          Swal.fire({
            title: 'Erreur',
            text: `Erreur d'ajout de l'agence`,
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
          });
        });
    });
    /* .catch(data => {
        alert(`Erreur d'ajout de l'image `, data);
      }); */
  }

  onFileChange(event) {
    var fileUploadControl = document.getElementById('image_file');
    var file = fileUploadControl.files[0];
    var name = event.target.files[0].name;
    var iconImageFile = new Parse.File(name, file);
    iconImageFile.save().then(() => {
      return this.setState({
        selectedFile: event.target.files[0],
        image: iconImageFile._url,
        iconImageFile: iconImageFile
      });
    });
  }

  render() {
    return (
      <>
        <PageHeader
          title="Compagnies et Agences"
          description="Créer des compagnies et des agences pour chaque compagnie"
          className="mt-3"
        ></PageHeader>
        <ReactBootstrap.Card.Body className="nav-pills-falcon m-0">
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="exampleFirstName">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Orabank"
                  defaultValue="Mark"
                  name="nom"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exampleLastName">
                <Form.Label>Type compagnie</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="..."
                  defaultValue="Finance"
                  name="type"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="countryId">
                <Form.Label htmlFor="countryId">Pays</Form.Label>
                <Form.Select
                  list="country-list"
                  name="country"
                  onChange={this.handleChange}
                >
                  <option key="1" value="choix">
                    Choisir un pays
                  </option>
                  {this.state.serverCountries.map(country => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="..."
                  defaultValue=""
                  name="email"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="email">
                <Form.Label>Contact</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="user@gmail.com"
                  defaultValue="22890000000"
                  name="phone"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="6" controlId="fichier">
                <div className="mb-3">
                  <label className="form-label">Choisir l'image</label>
                  <input
                    className="form-control"
                    type="file"
                    id="image_file"
                    name="image_file"
                    accept="image/*"
                    onChange={this.onFileChange}
                  />
                </div>
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Check
                required
                label="Agree to terms and conditions"
                feedback="You must agree before submitting."
              />
            </Form.Group>
            <Button
              onClick={e => {
                e.preventDefault();
                this.handleSubmit();
              }}
            >
              Ajouter la compagnie
            </Button>
          </Form>
        </ReactBootstrap.Card.Body>
        <PageHeader
          title="Compagnies et Agences"
          description="Créer des compagnies et des agences pour chaque compagnie"
          className="mt-4"
        ></PageHeader>
        <ReactBootstrap.Card.Body className="nav-pills-falcon m-0">
          <Row>
            <Col md={7}>
              <Form>
                <Form.Group>
                  <Form.Label
                    className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                    htmlFor="inputCountry"
                  >
                    Pays
                  </Form.Label>
                  <Form.Select
                    list="country-list"
                    name="country"
                    onChange={this.handleCompanyChange}
                  >
                    <option key="1" value="choix">
                      Choisir un pays
                    </option>
                    {this.state.serverCountries.map(country => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className="ls text-uppercase text-600 fw-semi-bold mb-0 fs--1"
                    htmlFor="inputCompany"
                  >
                    Compagnies
                  </Form.Label>
                  <Form.Select
                    list="company-list"
                    name="companyId"
                    onChange={this.handleCompanyChange}
                  >
                    <option key="1" value="choix">
                      Choisir une compagnie
                    </option>
                    {this.state.companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formHookname">
                  <Form.Label>Nom de l'agence</Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    placeholder="Entrez le nom"
                    onChange={this.handleCompanyChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formHookAddress">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Entrez l'adresse"
                    onChange={this.handleCompanyChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.name && errors.name.message} */}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="digitalId">
                  <Form.Label>Digital Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="digitalId"
                    placeholder="Digital Id"
                    onChange={this.handleCompanyChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    {/* {errors.email && errors.email.message} */}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row className="mb-3 g-3">
                  <Form.Group as={Col} controlId="geoPoint">
                    <Form.Label>Géolocalisation</Form.Label>
                    <Form.Control type="text" name="geoPoint" placeholder="" />

                    <Form.Control.Feedback type="invalid">
                      {/* {errors.password && errors.password.message} */}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div>
                    <input
                      className="btn btn-outline-primary"
                      type="file"
                      id="image_file"
                      name="image_file"
                      accept="image/*"
                      onChange={this.onFileChange}
                    />
                  </div>
                </Row>

                <Button
                  onClick={e => {
                    e.preventDefault();
                    this.handleSubmitAgence();
                  }}
                >
                  Ajouter l'agence
                </Button>
              </Form>
            </Col>
            <Col md="5">
              <Image
                src={this.state.image}
                roundedCircle
                style={{ width: 300, height: 300 }}
              />
            </Col>
          </Row>
        </ReactBootstrap.Card.Body>
      </>
    );
  }
}

export default FormValidation;
