export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Statistiques',
          to: '/',
          exact: true,
          active: true
        },
        {
          name: 'Infos générales',
          to: '/dashboard/saas',
          active: true
        }
      ]
    }
  ]
};
export const appRoutes = {
  label: 'app',
  children: [
    /*     {
      name: 'Calendar',
      icon: 'calendar-alt',
      to: '/app/calendar',
      active: true
    },
    {
      name: 'Events',
      icon: 'calendar-day',
      active: true,
      children: [
        {
          name: 'Create an event',
          to: '/events/create-an-event',
          active: true
        },
        {
          name: 'Event detail',
          to: '/events/event-detail',
          active: true
        },
        {
          name: 'Event list',
          to: '/events/event-list',
          active: true
        }
      ]
    }, */
    {
      name: 'Kanban',
      icon: ['fab', 'trello'],
      to: '/app/kanban',
      active: true
    }
  ]
};

export const pagesRoutes = {
  label: 'pages',
  children: [
    {
      name: 'Miscellaneous',
      icon: 'thumbtack',
      active: true,
      children: [
        {
          name: 'Invite people',
          to: '/miscellaneous/invite-people',
          active: true
        }
      ]
    }
  ]
};

export const modulesRoutes = {
  label: 'Modules',
  children: [
    {
      name: 'Insertions',
      active: true,
      icon: 'file-alt',
      children: [
        /*{
          name: 'Basic',
          active: true,
          children: [
            {
              name: 'Form control',
              to: '/forms/basic/form-control',
              active: true
            },
            {
              name: 'Input group',
              to: '/forms/basic/input-group',
              active: true
            },
            {
              name: 'Select',
              to: '/forms/basic/select',
              active: true
            },
            {
              name: 'Checks',
              to: '/forms/basic/checks',
              active: true
            },
            {
              name: 'Range',
              to: '/forms/basic/range',
              active: true
            },
            {
              name: 'Layout',
              to: '/forms/basic/layout',
              active: true
            }
          ]
        },
        {
          name: 'Advance',
          active: true,
          children: [
            {
              name: 'Advance select',
              to: '/forms/advance/advance-select',
              active: true
            },
            {
              name: 'Date picker',
              to: '/forms/advance/date-picker',
              active: true
            },
            {
              name: 'Editor',
              to: '/forms/advance/editor',
              active: true
            },
            {
              name: 'Emoji button',
              to: '/forms/advance/emoji-button',
              active: true
            },
            {
              name: 'File uploader',
              to: '/forms/advance/file-uploader',
              active: true
            },
            {
              name: 'Rating',
              to: '/forms/advance/rating',
              active: true
            }
          ]
        },
        {
          name: 'Floating labels',
          to: '/forms/floating-labels',
          active: true
        },
        {
          name: 'Wizard',
          to: '/forms/wizard',
          active: true
        }, */
        {
          name: 'Compagnies & Agences',
          to: '/forms/validation',
          active: true
        }
      ]
    },
    {
      name: 'Listes',
      icon: 'table',
      active: true,
      children: [
        /* {
          name: 'Utilisateurs & Roles',
          to: '/tables/basic-tables',
          active: true
        }, */
        {
          name: 'Compagnies',
          to: '/listes/compagnies',
          active: true
        }
      ]
    },
    {
      name: 'Services',
      icon: 'poll',
      active: true,
      to: '/services'
    },
    {
      name: 'Réservations',
      icon: 'poll',
      active: true,
      to: '/reservations'
    }
  ]
};

export default [dashboardRoutes, modulesRoutes, appRoutes];
