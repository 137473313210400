import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Dashboard from 'components/dashboards/default';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import MainRoutes from './MainRoutes';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import Login from './FirstLogin';
import Parse from 'parse';
Parse.serverURL = 'https://parseapi.back4app.com'; // This is your Server URL
// Remember to inform BOTH the Back4App Application ID AND the JavaScript KEY
Parse.initialize(
  'iYGJTat7H1sGd94LhYK8XFQw59TJpTo1MluQ8Dhi', // This is your Application ID
  'cfiF9113gvFLstOcbfxk1kuHKl9220q7U3jHpiqY' // This is your Javascript key
);
const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const [currentUser, setCurrentUser] = useState(null);

  const getCurrentUser = async function () {
    const currentUser = await Parse.User.current();
    setCurrentUser(currentUser);
    console.log(currentUser);
    return currentUser;
  };

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    getCurrentUser();
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {currentUser && (
        <div>
          {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
            <NavbarVertical />
          )}
          <ProductProvider>
            <div className={classNames('content', { 'pb-0': isKanban })}>
              <NavbarTop />
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <MainRoutes />
              </Switch>
              {!isKanban && <Footer />}
            </div>
          </ProductProvider>
        </div>
      )}
      {!currentUser && (
        <ProductProvider>
          <div className={classNames('content', { 'pb-0': isKanban })}>
            <Switch>
              <Route path="/" exact component={Login} />
            </Switch>
          </div>
        </ProductProvider>
      )}
    </div>
  );
};

export default MainLayout;
