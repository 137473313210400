import React, { useEffect } from 'react';
import is from 'is_js';

import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';

const FirstLogin = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <AuthCardLayout
        leftSideContent={
          <p className="text-white">
            Don't have an account?
            <br />
            <Link
              className="text-white text-decoration-underline"
              to="/authentication/card/register"
            >
              Get started!
            </Link>
          </p>
        }
      >
        <h3>Connexion</h3>
        <LoginForm layout="card" hasLabel />
      </AuthCardLayout>
    </>
  );
};

export default FirstLogin;
